html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  // height: 100%;
  .App{
    background-image: url('./assets/imgs/bg.png') ;
    background-position: 100% 100%;
  }
  .header {
    height: 0.776rem;
    position: fixed;
    width: 100%;
    top: 0;
    img {
      height: 0.776rem;
    }
  }

  .img_list{
    display: flex;
    flex-direction: column;
    align-items: center;
    .img_item {
      width: 63%;
      display: block;
    }
  }
  
  .footer {
    display: flex;
    justify-content: center;
    text-align: right;
    background: #638535;
    margin-top: .104rem;

    div {
      padding-right: 0.242rem;
      color: #fff;
      display: flex;
      p{
        margin-right: .26rem;
        font-size: 0.155rem;
      }
    }
  }
  
}
